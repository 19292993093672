.sprite {
  background: transparent url(/site/images/sprite.png) no-repeat center;

  @media only screen and (min-device-pixel-ratio: 2) {
    background-image: url(/site/images/sprite@2x.png);
    background-size: 269px 81px;
  }
}

.ico-brand {
  @extend .sprite;

  width: 269px;
  height: 54px;
  background-position: 0 0;
}

.ico-app-store {
  @extend .sprite;
  width: 91px;
  height: 27px;
  display: block;
  background-position: 0 -54px;
}

.ico-google-play {
  @extend .sprite;
  width: 90px;
  height: 27px;
  display: block;
  background-position: -91px -54px;
}

.ico-briba {
  @extend .sprite;
  width: 15px;
  height: 27px;
  background-position: -226px -54px;
  display: block;
}

.ico-facebook {
  @extend .sprite;
  display: inline-block;
  width: 9px;
  height: 18px;
  background-position: -182px -54px;
}

.ico-twitter {
  @extend .sprite;
  display: inline-block;
  width: 18px;
  height: 15px;
  background-position: -190px -54px;
}

.ico-linkedin {
  @extend .sprite;
  display: inline-block;
  width: 17px;
  height: 17px;
  background-position: -208px -54px;
}
