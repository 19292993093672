.main-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.main-container--header,
.main-container--footer {
  @extend .reset;
  @extend .container;
  margin: 0 auto;

  display: table;
  position: relative;
  li {
    display: table-cell;
    width: 100%;
    height: 20px;

    &:first-child,
    &:nth-child(2),
    &:last-child {
      &::before {
        height: 20px;
        content: ' ';
        display: block;
      }
    }

    &:nth-child(2)::before {
      width: 100%;
    }

    &:first-child,
    &:last-child {
      width: 20px;
      &::before {
        width: 20px;
        background-color: #161616;
      }
    }
  }
}

.main-container--header {
  top: -20px;
  li {
    position: relative;
    overflow: hidden;

    &:first-child,
    &:nth-child(2)::before,
    &:last-child {
      background-color: #382C20;
    }

    &:first-child::before {
      border-radius: 0 0 10px 0;
    }

    &:nth-child(2)::before {
      border-radius: 10px 10px 0 0;
    }

    &:nth-child(2)::after {
      display: block;
      content: ' ';
      width: 100%;
      height: 10px;
      border-bottom: 1px solid #9b968f;
      border-radius: 100%;
      position: absolute;
      top: -8px;
      margin: 0 auto;
    }

    &:last-child::before {
      border-radius: 0 0 0 10px;
    }
  }
}

.main-container--footer {
  bottom: -20px;
  li {
    &:first-child,
    &:nth-child(2)::before,
    &:last-child {
      background-color: #000;
    }

    &:first-child::before {
      border-radius: 0 10px 0 0;
    }

    &:nth-child(2)::before {
      border-radius: 0 0 10px 10px;
    }

    &:last-child::before {
      border-radius: 10px 0 0 0;
    }
  }
}

.page-content {
  margin: -10px 20px;
  padding: 5px;
  background: #FFF;
  box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.3);
  position: relative;

  @media only screen and (min-width: $screen-md-min) {
    margin: 10px 20px;
  }
}

.interna .page-content {
  padding: 10px;

  @media only screen and (min-width: $screen-md-min) {
    padding: 50px;
  }
}

.pagina__title {
  color: #666666;
  font: 20px 'Trajan Pro', Georgia, Times, "Times New Roman", serif;
  border-bottom: 1px solid #dfdddb;
  padding-bottom: 5px;
  margin-bottom: 10px;

  @media only screen and (min-width: $screen-md-min) {
    padding-bottom: 30px;
  }
}

.pagina_ilustration {
  text-align: center;

  @media only screen and (min-width: $screen-md-min) {
    padding-right: 25px;
  }

  img {
    margin: 0 auto;
  }
}

.pagina__conteudo {
  font: 14px 'Swis721 Cn BT', Helvetica, Arial, sans-serif;
  padding-top: 10px;
  line-height: 22px;

  @media only screen and (min-width: $screen-md-min) {
    padding-top: 20px;
  }
}

.localizacao_map {
  iframe {
    height: 300px;
    @media only screen and (min-width: $screen-md-min) {
      height: 500px
    }
  }
}
