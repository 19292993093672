@font-face {
  font-family: 'Swis721 Cn BT';
  src: url('/site/fonts/Swiss721BT-RomanCondensed.eot');
  src: local('Swiss 721 Condensed BT'), local('Swiss721BT-RomanCondensed'),
  url('/site/fonts/Swiss721BT-RomanCondensed.eot?#iefix') format('embedded-opentype'),
  url('/site/fonts/Swiss721BT-RomanCondensed.woff2') format('woff2'),
  url('/site/fonts/Swiss721BT-RomanCondensed.woff') format('woff'),
  url('/site/fonts/Swiss721BT-RomanCondensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trajan Pro 3';
  src: url('/site/fonts/TrajanPro3-Regular.eot');
  src: local('Trajan Pro 3'), local('TrajanPro3-Regular'),
  url('/site/fonts/TrajanPro3-Regular.eot?#iefix') format('embedded-opentype'),
  url('/site/fonts/TrajanPro3-Regular.woff2') format('woff2'),
  url('/site/fonts/TrajanPro3-Regular.woff') format('woff'),
  url('/site/fonts/TrajanPro3-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
