.artigos__sidebar {
  margin-top: 20px;

  @media only screen and (min-width: $screen-md-min) {
    margin-top: -40px;
  }
}

.artigos__sidebar__item {
  padding: 25px 0;
  &:not(:first-child) {
    border-top: 1px solid #e2e2e2;
  }
}

.artigos__sidebar__item__titulo {
  font-size: 17px;
  color: #333;
  padding-bottom: 5px;
  display: inline-block;
}

.artigos__sidebar__item__chamada {
  font-size: 14px;
  color: #999;
  padding-bottom: 5px;
}

.artigos__sidebar__item__data {
  font-size: 12px;
}

.artigos__conteudo {
}

.artigos__conteudo__titulo {
  margin: 0 0 25px;
}

.artigos__conteudo__chamada {
  font-size: 18px;
  color: #333;
  margin: 0 0 20px;
}

.artigos__conteudo__conteudo {
}

.artigos__sidebar__nav {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 9px 0;
  color: #333;
  text-align: center;
  margin-top: 15px;
}

.artigos__sidebar__nav__left {
  width: 20px;
  height: 20px;
  float: left;
  padding: 5px 10px;
  &:after {
    display: none;
  }
}

.artigos__sidebar__nav__right {
  width: 20px;
  height: 20px;
  float: right;
  padding: 5px 10px;
  margin-right: 7px;
  &:after {
    display: none;
  }
}
