.footer {
  position: relative;
  color: #858585;
  font: 12px 'Swis721 Cn BT', Helvetica, Arial, sans-serif;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: justify;
}

.footer__address {
  text-align: center;
  margin: 0;
  @media only screen and (min-width: $screen-md-min) {
    float: left;
    text-align: right;
    margin-left: 30px;
  }
  @media only screen and (min-width: $screen-lg-min) {
    margin-top: 4px;
  }
}

.footer__copy {
  text-align: center;
  @media only screen and (min-width: $screen-md-min) {
    float: left;
    text-align: right;
    margin-left: 40px;
  }
  @media only screen and (min-width: $screen-lg-min) {
    margin-top: 5px;
  }
}

.footer__stores {
  text-align: center;
  margin-top: 15px;

  @media only screen and (min-width: $screen-md-min) {
    float: right;
    margin-top: 25px;
    margin-right: 15px;
  }

  @media only screen and (min-width: $screen-lg-min) {
    margin-top: 0;
  }

  .ico-app-store,
  .ico-google-play {
    display: inline-block;
    margin: 0 3px;
  }
}

.footer__briba {
  position: absolute;
  bottom: 28px;
  right: 20px;
}
