.header {
  @extend .container;
  padding-top: 20px;
  padding-bottom: 0;
  position: relative;

  @media only screen and (min-width: $screen-md-min) {
    padding-top: 35px;
    padding-bottom: 40px;
  }
}

.header__brand {
  @extend .reset;
  @extend .ico-brand;
  @extend .text-hide;

  margin: 0 auto;
  width: 240px;
  height: 48px;
  background-size: 240px;
  position: relative;
  left: 20px;

  @media only screen and (min-width: 350px) {
    width: 269px;
    height: 54px;
    background-size: 269px;
  }

  @media only screen and (min-width: 400px) {
    left: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.header__nav-toggle {
  @extend .reset;
  padding: 10px;
  float: left;
  margin-left: -10px;

  @media only screen and (min-width: 350px) {
    margin-top: 5px;
  }

  @media only screen and (min-width: $screen-md-min) {
    display: none;
  }

  .icon-bar {
    width: 30px;
    height: 3px;
    background-color: #FFF;;
    display: block;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.header__nav {
  @extend .reset;
  clear: both;
  max-height: 0;
  overflow: hidden;
  padding-top: 15px;
  transition: all .5s linear;

  &.open {
    max-height: 500px;
  }

  @media only screen and (min-width: $screen-md-min) {
    max-height: none;
    padding: 0;
    margin: 0 auto -37px;
    width: auto;
    text-align: center;
    z-index: 55;
    overflow: visible;
  }

  li {
    position: relative;
    @media only screen and (min-width: $screen-md-min) {
      display: inline-block;
      &:hover .header__nav__submenu {
        display: block;
      }
      &:not(:last-child) {
        a {
          border-right: 1px solid #605e5b;
        }
      }
    }
  }

  a {
    font: 12px 'Trajan Pro', Georgia, Times, "Times New Roman", serif;
    text-transform: uppercase;
    color: #FFF;
    padding: 10px 0;
    display: inline-block;
    @media only screen and (min-width: $screen-md-min) {
      padding: 3px 12px 0 12px;
    }
  }
}

.header__nav__submenu {
  @extend .reset;
  margin-left: 15px;
  text-align: left;

  @media only screen and (min-width: $screen-md-min) {
    display: none;
    margin: 0 0 0 -3px;
    padding-top: 18px;
    padding-bottom: 5px;
    position: absolute;
    z-index: 555;
    background-color: rgba(56, 44, 32, 0.9);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    li {
      display: block;
    }
    a {
      padding-top: 8px;
      padding-bottom: 8px;
      white-space: nowrap;
    }
  }

  a {
    border: none !important;
  }
}

.header__nav-lang {
  font: 12px 'Trajan Pro', Georgia, Times, "Times New Roman", serif;
  color: #d47301 !important;
  @media only screen and (min-width: $screen-md-min) {
    float: right;
    position: absolute;
    z-index: 55;
    right: 15px;
    top: 75px;
  }
}

.header__nav-social {
  @extend .reset;
  position: absolute;
  right: 0;
  top: 128px;

  @media only screen and (min-width: $screen-md-min) {
    top: 200px;
  }

  li {
    padding: 6px 10px 6px 6px;
    text-align: center;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, .2);
    }
  }
}

.header__player {
  position: absolute;
  right: 35px;
  top: 75px;
}

.header__player__prev {
  @extend .ico-prev;
  float: left;
  margin-right: 8px;
}

.header__player__play {
  @extend .ico-play;
  float: left;
  margin-right: 8px;
}

.header__player__pause {
  @extend .ico-pause;
  float: left;
  margin-right: 8px;
}

.header__player__next {
  @extend .ico-next;
  float: left;
  margin-right: 8px;
}

.header__player__name {
  width: 120px;
  border-right: 1px solid #979898;
  padding: 0 10px;
  color: #c7c7c7;
  float: left;
  margin-top: -5px;
  overflow: hidden;
}

.header__player__name__content {
}
