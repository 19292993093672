@import 'variables';
@import 'bootstrap';
@import "fonts";
@import "fancybox/dist/scss/jquery.fancybox";

html, body {
  position: relative;
  width: 100%;
}

html {
  height: 100%;
}

.site-body {
  min-height: 100%;
  display: none;
  background-color: #161616;
}

label {
  font-weight: normal;
}

a {
  transition: .5s color;
  color: #555555;
  &:hover {
    color: #372C1F;
  }
}

.cke_editable {
  padding: 15px;
}

.main-container {
  background: #382c20;
  background: linear-gradient(to bottom, #382c20 0%, #382c20 50%, #000000 50%, #000000 100%);
}

@import 'helpers';
@import 'sprite';
@import 'header';
@import 'page';
@import 'banners';
@import 'galeria';
@import 'artigos';
@import 'noticias';
@import 'footer';
