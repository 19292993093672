.reset {
  padding: 0;
  margin: 0;
  list-style: none;
  border: none;
  background: none;
}

.ico-prev,
.ico-next {
  width: 12px;
  height: 10px;
  display: block;

  &:before,
  &:after {
    width: 6px;
    height: 10px;
    display: block;
    content: '';
    float: left;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}

.ico-prev {
  &:before,
  &:after {
    border-right: 5px solid #b3b4b4;
  }
}

.ico-next {
  &:before,
  &:after {
    border-left: 5px solid #b3b4b4;
  }
}

.ico-play {
  width: 6px;
  height: 10px;
  display: block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #b3b4b4;
}

.ico-pause {
  width: 8px;
  height: 10px;
  border-left: 3px solid #b3b4b4;
  border-right: 3px solid #b3b4b4;
  display: block;
}

.marquee {
  width: 100px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  text-indent: 0;
  animation: marquee 15s linear infinite;
}

.marquee span:hover {
  animation-play-state: paused;
}

.marquee.pause span {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
