.galeria__images {
  position: relative;
  width: 100%;
  z-index: 1;
}

.galeria__images__clip {
  position: relative;
  width: 100%;
  z-index: 1;
}

.galeria__images__item {
  width: 100%;
}

.galeria__images__item__descricao {
  width: 100%;
  background-color: rgba(0, 0, 0, .3);
  text-align: right;
  color: #FFFFFF;
  font: 300 16px 'Calibri', Arial, sans-serif;
  padding: 10px 15px;
  margin-top: -40px;
  position: relative;
  z-index: 555;
}

.galeria__images__item__count {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  margin: 12px 0 0;
  padding-bottom: 10px;
}

.galeria__images__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 999;
  display: block;

  @media (min-width: $grid-float-breakpoint) {
    width: 100px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -43px;
    margin-left: -17px;
    display: none;

    @media (min-width: $grid-float-breakpoint) {
      margin-left: -23px;
    }
  }

  &:hover img {
    display: block;
  }
}

.galeria__images__arrow--left {
  left: 0;
}

.galeria__images__arrow--right {
  right: 0;
}
