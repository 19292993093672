
.noticias {

}

.noticias__item:not(:last-child) {
  margin-bottom: 45px;
}

.noticias__item__nome {
  border-bottom: 1px solid #e2e2e2;
  color: #555555;
  margin: 10px 0;
  padding: 5px 0;

  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
}

.noticias__item__conteudo__item {
  padding: 10px 0;
  margin: 10px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}

.noticias__item__conteudo__item__title {
  font-size: 18px;
}

.noticias__item__conteudo__item__description {

}

.noticias__item__conteudo__item__date {
  font-size: 12px;
  color: #999;
}
